/* Bootstrap overrides */
.container{
  max-width: 1600px;
}
.input-group-text{
  background: transparent;
  border: none;
  font-weight: bold;
}

.row-header{
  width: 100%;
  justify-content: space-between;
}

.navbar-brand h1{
  font-size: 20pt;
}

ul.navbar-nav {
  right: 0px;
}

label{
  font-size: 9pt;
  margin-bottom: 2px;
}

label {
  font-weight: bold !important;
}

.nav-item {
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
}

.nav-item a{
  color: white;
  font-size: bold;
}

.nav-item a:hover{
  text-decoration: none;
  color: #ccc;
}

.nav-item a.active{
  color: red;
}

.text-danger{
  font-size: 8pt;
}



/* Custom classes */

.non-auth-printing-hidden{
  display: none;
}

.border-bottom-black{
  border-bottom: 1px solid #333;
}

.main-container{
  display:flex;
  flex-flow: column;

}

.header-container{
  padding-top: 14px;
  padding-left: 30px;

  .navbar{
    padding-left: 0px;
    padding-right: 0px;
  }

}


.link-container a {
    text-decoration: none;
}

.toggle-open{
  transform: rotate(90deg);
}

.custom-checkbox{
  position: absolute;
  margin-top: 2px;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.page-container, .header-container .nav-contain{
  flex-wrap: nowrap;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;

  #oaa-logo-header{
    max-width: 355px;
    width: 355px;
    min-width: 100%;
  }

}

.page-container{
  padding: 0px 20px; 
}


.subhead-description{
  font-size: 10pt;
  line-height: 1.2em;

  div {
    display: inline-block;
    padding: 0 5px;

  }
}


.custom-body-container{
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 20px 20px;
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.05);
  box-shadow: 2px 3px 2px rgba(0,0,0,0.1);


  h2{
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    text-indent: 0rem;
    font-size: 16pt;
  }

  .link-container{
    text-align: right;
    right: 0;
  }

  .col-form-label, label{
    font-size: 9pt;
    font-weight: bold;
  }

  .appended-units{
    font-size: 12pt;
    font-weight: bold;
  }

}

.sources-section{

  div {
    margin-bottom: 0px;
  }

  p {
    text-indent: 0;
    font-size: 11pt;
    margin-bottom: 0px !important;
  }

  a{
    color: red;
  }

}

.primary-form-toggle-button {
  position: absolute;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  float: none;
  font-size: 16pt;
  cursor: pointer;
  transition: transform .2s ease-in-out;
  margin-left: -10px;
  margin-top: 30px;
  z-index: 1001;

  &.sources-toggle{
    margin-top: 3px;
  }

  span {
    margin: 0 auto;
  }

}

/* Chart Components */
.house-container {
  margin: 0 auto;
  min-width: 100%;
  min-height: 320px;
  max-width: 100%;
  max-height: 400px;
  border: 0px;
  -webkit-box-shadow:none;

  p {
    line-height: 1.25em;
    text-indent: 0px;
    color: black;
    font-size: 14pt;
    text-align: left;
    margin-bottom: 5px;

    span{
      display: block;
      text-indent: 0px;
      color: black;
      font-size: 30pt;
      text-align: left;
      font-weight: bold;
      width: 100%;
    }

  }

}

.form-sub-head{
  font-size: 12px;
  font-weight: bold;
}

.footer-area{
  background-color: #dfe2e2;
  margin-top: 60px;
  p{
    font-size: 11pt;
  }

  .footer-logo{
    img{
      width: 40px;
      height: 40px;
    }
  }

}

#barband-container, #estimate-arrow-container, #estimate-line-container, #donut-chart{
  width: 100%;
  margin: 0 auto;

}

#estimate-arrow-container{
  width: calc(100% + 26px);
  margin-left: -13px
}

#donut-chart svg{
  margin: 0 auto;
}

#estimate-arrow-container, #estimate-line-container{
  transition: all .3s ease-in-out;
}


#estimate-line-container, #estimate-line-containerprint{
  position: relative;
}

#estimate-line-container #line-indicator, #estimate-line-containerprint #line-indicatorprint{
  position: absolute;
  margin-top: -175px;
}

#estimate-arrow-container, #estimate-arrow-containerprint{
  height: 20px;
  position:relative;

  .indicator-caret{
    position: absolute;
    font-size: 32pt;
    top: -14px;
  }
}

.chart-text{
  padding: 5px 0px;
  margin-bottom: 0px;
}

.chart-text p {
  font-size: 10pt;
  text-align: left;
  text-indent: 0px;
  margin-bottom: 3px;
}

.grid-list{
  flex-flow: nowrap;
  margin-right: 0px;

  .col-md-7{
    padding-right: 0px;
    text-align: right;
  }

  .col-md-6{
    display: inline-block;
    padding-right: 3px;
    padding-left: 3px;
    flex: 0 0 48%;
    text-align: right;
  }

  span{
    font-size: 9pt;
  }
}

.estimate-basic-info {

  max-width: 360px;
  margin: 0 auto;

  p{
    font-size: 10pt;
    text-align: left;
    text-indent: 0px;
    margin-bottom: 3px;
  }
}

.form-group.row{
  margin-bottom: 5px;
}

.small-input, .input-group-text{
  padding: 2px 2px;
  font-size: 9pt;
  height: calc(1.5em + .5rem);
}

.small-input{
  max-width: 150px;
  border-radius: 0px;
}

.input-group-text{
  width: 75px;
}

.slide-graphic{
  transition: margin-left .3s ease-in-out;
}

.side-drawer{
  position: relative;
  padding-left: 30px;
  background-color: white;
  z-index: 1000;
  height: auto;
  transition: all .3s ease-in-out;
  line-height: 1.1;

  .primary-form{
      position: relative;
      margin-left: -10px;
      transition: left .3s ease-out;
      background-color: white;

      .sources-section .collapse.show{
        padding: 5px 10px 20px;
      }
  }

  .icon-holder {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    cursor: pointer;
    right: 0px;
    justify-content: center;
    text-align: right;
    transition: width .3s ease-in-out;
    svg{
      font-size: 36px;
      color: #343a40;
      transition: transform .3s ease-in-out;
    }
  }

  &.closed {
    width: 90px;
    .primary-form{
      opacity: 0;
      height: 0;
      max-height: 100%;
    }

  }

  &.open {
    padding-left:20px;
    position:relative;

    .primary-form{
      left: 40px;
    }
    .icon-holder {
      justify-content: flex-end;
      svg{
        transform: rotate(-540deg);
      }
      width: 100%;

    }
  }
}

/* Date Picker custom style */

.react-date-picker, .react-date-picker__wrapper{
  max-width: 100%;
}

.react-date-picker__inputGroup{
  font-size: 10pt;
  min-width: calc((3px * 3) + 0.14em * 8 + 0.217em * 2);

}

.react-date-picker__button{
  padding: 4px;
}

.react-date-picker__button svg{
  width: 10px;
}

#oaa-logo{
  max-width: 360px;
  margin: 0 auto;
  height: auto;
  object-fit: cover;
}

.text-container{

  max-width: 360px;
  margin: 0 auto;

  .col-md-2{
    flex: 0 0 25.9% !important;
    width: 25.9% !important;
    max-width: 25.9% !important;
  }

  .col-md-8{
    flex: 0 0 74.9% !important;
    width: 74.9% !important;
    max-width: unset !important;
    padding-left: 0px;
  }

  .col-sm-6.col-md-5{
    padding-right:10px;
    padding-left:3px;
    text-align: right;
  }

}

.co2-number{
  h2 {
    color:rgb(0, 102, 204);
    font-size: 36pt;
    font-family: "Droid Sans", sans-serif;
    font-weight: bold;
    line-height:0.8em;
  }
  h3 {
    font-size: 9pt;
    font-weight: normal;
    white-space: nowrap;
  }
}

.button-container {

   padding-left: 25px;
   padding-right: 25px;


  .col-md-6{
    text-align: center;
    padding: 0px 5px;

    button {
      width: 100%;
      border-radius: 0px;
      background-color: black;
      font-weight: bold;
    }

  }

}


#estimate-container-print{
  display: flex;
  display: -webkit-flex;
  align-self: center;
  -webkit-flex-align: center;
  flex-flow: column !important;
  visibility: hidden;
  position: absolute;
  max-width: 440px;
  max-height: 900px;

  .col-lg-6{
    max-width: 100%;
    flex: 0 0 100%;
  }

  .house-container{
    background-size: contain !important;
    
    p {
      font-size: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

  }

  h2 {
    margin-top: 10px;
    white-space: nowrap !important;
  }

  img {
    object-fit: contain;
    max-width: 100%;
  }

}

.empty-print{
  display: none !important;
}


@media print {

  .primary-form{
    display: none;
  }
  
  .print-show-all{
    margin-top: 2in;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    #estimate-container, #estimate-container-non-auth{
      padding-top: 40px;
      padding-bottom: 40px;
      border: 2px solid black;
      width: 440px;
      margin-top: 1.75in;
    }

    #estimate-container-non-auth{
      min-height: 900px;

      h1{
        text-align: center;
        font-weight: bold;
        font-size: 32px;
      }

    }



    .non-auth-printing{
      display:none;
      position: relative;
      overflow: hidden;

      &:before{
        width: 200%;
        height: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: "";
        background: url(public/images/oaa.svg);
        background-position: 20 20;
        background-repeat: repeat;
        background-size: 50% 35%;
        transform: rotate(30deg) translate(-50px, 50px) ;
        position: absolute;
        opacity: 0.1;
        overflow: hidden;
      }
    }

    .non-auth-printing-hidden{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .printer{
    display: block !important;
  }

}